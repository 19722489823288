export const getMindEdgeStyle = (themeColor) => {
  let mindEdgeStyle = { lineWidth: 2, stroke: themeColor };
  return mindEdgeStyle;
};
export const getMindNodeStyle = (
  themeColor,
  btnTextColor,
  firstBgColor,
  secondBgColor,
  thirdTextColor,
  lineThemeColor
) => {
  let mindNodeStyle = {
    //节点默认背景颜色
    btnStyle: {
      fill: "transparent",
      stroke: "transparent",
      lineWidth: 2,
    },
    //节点背景颜色数组
    btnStyles: [
      { fill: firstBgColor, stroke: firstBgColor, lineWidth: 2 },
      { fill: secondBgColor, stroke: secondBgColor, lineWidth: 2 },
    ],
    //节点文本颜色数组
    btnTextStyles: [
      { fill: btnTextColor, fontWeight: 700, fontSize: 16 },
      { fill: thirdTextColor, fontWeight: 600, fontSize: 14 },
    ],
    //重要果实hover和selected样式
    btnTypeStyles: {
      1: {
        // hoverFill: "#ffd6d6",
        // selectedFill: "#ffd6d6",
        hoverStroke: "#DF7271",
        selectStroke: "#DF7271",
        // hoverTextColor: "#333",
        // selectTextColor: "#333",
        show: false, //图标是否显示
      },
    },
    //重要果实的配置
    specialStyle: {
      1: {
        fontFamily: "iconfont",
        fill: "#DF7271",
        text: "\ue604",
        fontSize: 14,
        fontWeight: 700,
      },
    },
    //节点hover边框颜色
    btnHoverStyle: {
      stroke: themeColor,
    },
    // 节点hoverd多层样式
    btnHoverStyles: [
      {
        fill: firstBgColor,
        stroke: themeColor,
      },
      {
        fill: secondBgColor,
        stroke: themeColor,
      },
    ],
    //节点selected边框颜色
    btnSelectedStyle: {
      stroke: themeColor,
    },
    //节点selected多层边框颜色
    btnSelectedStyles: [
      {
        stroke: themeColor,
      },
    ],
    // 孙节点文本默认颜色
    btnTextStyle: {
      fill: thirdTextColor,
      fontSize: 13,
      fontStyle: "normal",
      fontWeight: 400,
      fontFamily:
        '"Microsoft YaHei", "PingFang SC", "Microsoft JhengHei", sans-serif',
      textBaseline: "middle",
      lineHeight: 25,
      maxCount: 16,
      maxWidth: 200,
    },
    // 数字文本颜色
    collapsedNumberTextStyle: {
      fill: themeColor,
      fontSize: 12,
    },
    // 数字背景颜色
    collapsedNumberStyle: {
      fill: "transparent",
    },
    // 加减号背景颜色
    collapsedSelectedStyle: {
      r: 9,
      lineWidth: 2,
      fill: themeColor,
      stroke: themeColor,
    },
    // 加减号文本颜色
    collapsedSelectedTextStyle: {
      fill: "#ebebeb",
      fontSize: 16,
      lineHeight: 18,
      fontWeight: 600,
      fontFamily: "iconfont",
    },
    // 关联模型
    linkDotStyle: {
      fill: lineThemeColor,
      r: 4,
    },
    linkLabelBgStyle: {
      fill: lineThemeColor,
    },
    linkLabelTextStyle: {
      fontStyle: "normal",
      fontFamily:
        '"Microsoft YaHei", "PingFang SC", "Microsoft JhengHei", sans-serif',
      fill: "#222",
      fontSize: 12,
      fontWeight: 400,
      textAlign: "left",
      textBaseline: "middle",
      lineHeight: 10,
      maxWidth: 200,
    },
    //   //双线图标
    doubleIconStyle: {
      fontFamily: "iconfont",
      fill: "#a48aff",
      text: "\ue8d3",
      fontSize: 14,
      fontWeight: 700,
    },
    //   //link ready
    //   linkReadyStyle: {
    //     stroke: lineThemeColor,
    //     btnStyle: {
    //       fill: lineThemeColor,
    //     },
    //     btnTextStyle: {
    //       fontFamily: "iconfont",
    //       fill: "#fff",
    //       text: "\ueaf3",
    //       fontWeight: 600,
    //       fontSize: 10,
    //     },
    //   },
  };
  return mindNodeStyle;
};
export const getMindEdgeLinkStyle = (lineThemeColor) => {
  let mindEdgeLinkStyle = {
    //双线标签背景
    edgeLabelBgStyle: { fill: lineThemeColor },
    //双线标签文字
    edgeLabelTextStyle: {
      fontStyle: "normal",
      fontFamily:
        '"Microsoft YaHei", "PingFang SC", "Microsoft JhengHei", sans-serif',
      fill: "#222",
      fontSize: 12,
      fontWeight: 400,
      textAlign: "left",
      textBaseline: "middle",
      lineHeight: 10,
      maxWidth: 200,
    },
    //线条样式
    edgePathStyle: { stroke: lineThemeColor, shadowColor: "#4f8946" },
    //线条中间点样式
    edgeDotStyle: { fill: lineThemeColor, r: 2 },
    //线条两端小圆圈
    circleDotStyle: {
      r: 4,
      lineWidth: 2,
      stroke: lineThemeColor,
      fill: "#fff",
    },
    //双线中间的箭头
    arrowStyle: {
      path: `M 0,0 L 9,9, L 0,0 L 9,-9 Z`,
      stroke: lineThemeColor,
      fill: lineThemeColor,
      lineDash: [1, 1],
    },
  };
  return mindEdgeLinkStyle;
};
