import { createApp, h } from "vue";
import App from "./App.vue";
import { router } from "./router"; // 导入路由文件
import store from "./store"; // 导入vuex文件
import api from "./api"; // 导入api接口
import ElementPlus from "element-plus"; //导入element-plus框架
require("@/assets/css/index.css");
// import * as ElIconModules from '@element-plus/icons'// 统一导入el-icon图标
// import { transElIconName } from './utils/utils.js'// 导入转换图标名称的函数
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
// "element-plus": "^2.2.5",
// 主题CSS
require("@/assets/css/default.scss");
// require("@/assets/css/dark.scss");
// require("@/assets/css/dark2.scss");
require("@/assets/css/dark.scss");
require("@/assets/css/root.scss");
// svg图标注册
import "@/svgIcon/index";
import svgIcon from "@/svgIcon/index.vue";

// Require Froala Editor js file.
require("froala-editor/js/froala_editor.pkgd.min.js");

// Require Froala plugins js files.
require("froala-editor/js/plugins/paragraph_style.min.js");
require("froala-editor/js/plugins/paragraph_format.min.js");
require("froala-editor/js/plugins/colors.min.js");
require("froala-editor/js/plugins/font_family.min.js");
require("froala-editor/js/plugins/font_size.min.js");
require("froala-editor/js/plugins/link.min.js");
require("froala-editor/js/plugins/image.min.js");
require("froala-editor/js/plugins/line_height.min.js");
// require('froala-editor/js/plugins/inline_class.min.js')
// require('froala-editor/js/plugins/inline_style.min.js')
require("froala-editor/js/plugins/lists.min.js");
// require("froala-editor/js/plugins/quote.min.js");
// require('froala-editor/js/plugins/align.min.js')
// require('froala-editor/js/third_party/image_tui.min.js')
// require('froala-editor/js/plugins/markdown.min.js')
// require('froala-editor/js/plugins/print.min.js')
// require('froala-editor/js/plugins/quick_insert.min.js')

// Require Froala language js file.
// require('froala-editor/js/languages/zh_cn.js')
require("@/components/froala-editor/js/languages/zh_cn.js");

// Require Froala Editor css files.
require("froala-editor/css/froala_style.min.css");

require("@/components/froala-editor/css/froala_editor.pkgd.min.css");
require("@/components/froala-editor/css/themes/dark.css");
// plugins
require("@/components/froala-editor/js/plugins/save.min.js");
require("@/components/froala-editor/js/plugins/image.min.js");
require("@/components/froala-editor/js/plugins/segment_spacing.js");

// require('@/components/froala-editor/js/plugins/html2pdf.bundle.js')
// require('@/components/froala-editor/js/plugins/mathtype-froala3/wiris')
import VueFroala from "@/components/froala-editor/vue-froala.js";

// jsBridge
import Bridge from "@/utils/jsBridge.js";

import localforage from "localforage";
import "@/utils/global.js";
import { proxyApi } from "./workers/ProxyApi";
// localforage.config({
//   name: "article_content",
// });

import "vue-cropper/dist/index.css";

const app = createApp(App);

// 设置全局变量api
app.config.globalProperties.$api = api;

// jsBridge
app.config.globalProperties.$bridge = Bridge;

app.config.globalProperties.$localForage = localforage;

app.config.globalProperties.$proxy = proxyApi;

app.config.globalProperties.$getIconType = (item) => {
  if (!item.parent) {
    return item.iconType !== 99 &&  item.iconType !== 2? 99:item.iconType;
  }
  return item.iconType;
}
app.config.globalProperties.$isMarkEmphasis = (item) => {
  return (!item.iconDetail && item.iconType===1) || (item.iconDetail && item.iconDetail.marked)
}
app.config.globalProperties.$getNewIconType = (item, newParent) => {
  if (!newParent || newParent.id==='root') {
    return item.iconType !== 99 &&  item.iconType !== 2? 99:item.iconType;
  }
  return item.iconType;
}

const MathLive = require("mathlive");
app.config.globalProperties.$MathLive = MathLive;

// 统一注册el-icon图标
// for (let iconName in ElIconModules) {
//     app.component(transElIconName(iconName), ElIconModules[iconName])
// }
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
store.commit("autoLogin");

// 屏蔽警告信息
app.config.warnHandler = () => null;
// app.config.errorHandler = (err, vm, info) => {
//   console.log("--error--111-", err, vm.name, info)
//   proxyApi.log({
//     key: 'error',
//     content: info,
//     vm: vm.name,
//     err
//   })
// }
app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(VueFroala);
app.component("svg-icon", svgIcon);
app.mount("#app");
if (window.location.origin.startsWith("https")) {
  window.onerror = (msg, source, line, column, error) => {
    // 区分是否是js错误
    proxyApi.log({
      key: "error",
      content: msg,
      source,
      line,
      column,
      error: error,
    });
    return true;
  };
}
window.addEventListener("unhandledrejection", function (evt) {
  // console.log("ssss----",evt); // 打印抛出的异常
  proxyApi.log({
    key: "error",
    content: evt.reason.message,
    error: evt.reason.stack,
  });
});
