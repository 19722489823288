<template>
  <div class="loading-page d-center d-column">
    <!--    <svg-icon icon-class="logo-1" class-name="loading-logo"></svg-icon>-->
    <svg-icon icon-class="new-logo3" class="loading-logo"></svg-icon>
    <!-- <svg-icon icon-class="new-logo4" class="loading-logo2"></svg-icon> -->
    <progress-bar :progress="progress" />
    <div class="loading-info">我等手握模型树之人，皆是命运的操盘手</div>
  </div>
</template>

<script>
import ProgressBar from "../components/widget/ProgressBar";
export default {
  name: "LoadingPage",
  components: { ProgressBar },
  props: {
    progress: {
      // 当前进度
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped></style>
