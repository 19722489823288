import { isWin } from "../components/antv-mindmap/mxs-mindmap.es";

export const LoginState = {
  loginIn: 0, // 正在登录
  hasLogin: 1, // 已经登陆
  noLogin: 2, // 没有登陆
};

export const DragShowMode = {
  default: 0,
  full: 1,
  hide: 2,
};
export const DragCrossMode = {
  enabled: 0,
  disabled: 1,
  leftDisabled: 2,
  rightDisabled: 3,
};

export const defaultTheme = "dark7";

//配置快捷键
export const shortcutKeys = [
  {
    eventKey: "event-article",
    key: "open-left", //侧边栏
    shortcutKey: "Meta+L",
    shortcutText: "⌘ + L",
    checkInput: true, //具有输入focus的时候快捷键不生效
    enabled: true, //是否开启快捷键
  },
  {
    eventKey: "event-article",
    key: "open-mind", //开关导图
    shortcutKey: "Meta+M",
    shortcutText: "⌘ + M",
    checkInput: false,
    enabled: true, //是否开启快捷键
  },
  {
    eventKey: "event-article",
    key: "open-document", //开关文档
    shortcutKey: "Meta+D",
    shortcutText: "⌘ + D",
    checkInput: false,
    enabled: true, //是否开启快捷键
  },
  {
    eventKey: "event-article",
    key: "doc-mind", //文档导图切换
    shortcutKey: "Meta+K",
    shortcutText: "⌘ + K",
    checkInput: false,
    enabled: true, //是否开启快捷键
  },
  {
    eventKey: "event-article",
    key: "open-ai", //打开AI
    shortcutKey: "Meta+B",
    shortcutText: "⌘ + B",
    checkInput: false,
    enabled: true, //是否开启快捷键
  },
];

shortcutKeys.forEach((item) => {
  if (isWin) {
    if (item.shortcutKey) {
      item.shortcutKey = item.shortcutKey.replace("Meta", "Ctrl");
    }
    if (item.shortcutText) {
      item.shortcutText = item.shortcutText.replace("⌘", "Ctrl");
    }
  }
  item.shortcutKey = item.shortcutKey.toLowerCase();
});
